<template>
  <div>
    <input
      type="text"
      class="userSearch"
      placeholder="جستجو براساس نام و تلفن همراه"
      v-model="searchValue"
    />
    <div class="userTypeColor">
      <span
        v-for="(item, index) of userTypeAccess"
        :key="index"
        :style="getUserTypeColor(item.value)"
        @click="setUserTypeFilter(item.value)"
        >{{ item.name }}</span
      >
      <span @click="setUserTypeFilter(null)" class="deleteFilterUserType"
        ><i class="fa fa-times" aria-hidden="true"></i
      ></span>
    </div>

    <div class="userListBox">
      <div
        class="userItem"
        v-for="(item, index) of userShowList"
        :key="index"
        :style="getUserTypeBorderColor(item.userType)"
      >
        <h4>{{ item.fullName }}</h4>
        <hr />
        <h5>{{ item.phone }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "allUserConnection",
  props: ["userTypeAccess"],
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
      userList: [],
      userShowList: [],
      searchValue: "",
      searchType: null,
    };
  },
  watch: {
    searchValue() {
      this.filterUserList();
    },
  },
  methods: {
    ...mapActions("correspondences", ["GetAllUsersInfoRelated"]),
    async fetchData() {
      this.loading = true;
      let result = await this.GetAllUsersInfoRelated({});
      this.loading = false;
      if (result) {
        this.userList = [];
        this.userShowList = [];
        result.forEach((element) => {
          this.userList.push({
            fullName: element.fullName,
            phone: element.phone,
            userType: element.userType,
          });
        });
        this.userShowList = this.userList;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
    getUserTypeColor(userType) {
      switch (userType) {
        case 1:
          return "background-color:#1a00ff38;color:#1000a3";
        case 2:
          return "background-color:#1a00ff38;color:#1000a3";
        case 21:
          return "background-color:#3399ff59;color:#005293";
        case 3:
          return "background-color:#2eb85c59;color:#00551c";
        case 4:
          return "background-color:#f9b11563;color:#b97f00";
        case 5:
          return "background-color:#1546f963;color:#001a97";
        case 6:
          return "background-color:#ac15f938;color:#6200ff";
        case 7:
          return "background-color:#f9155438;color:#990063";
        case 8:
          return "background-color:#f97e1538;color:#995500";
        default:
          return "background-color:#1a00ff38;color:#1000a3";
      }
    },
    getUserTypeBorderColor(userType) {
      switch (userType) {
        case 1:
          return "border: 1px solid rgb(205 199 255);background-color: rgb(205 199 255 / 17%);";
        case 2:
          return "border: 1px solid rgb(205 199 255);background-color: rgb(205 199 255 / 17%);";
        case 21:
          return "border: 1px solid rgb(184 219 255);background-color: rgb(184 219 255 / 18%);";
        case 3:
          return "border: 1px solid rgba(46, 184, 92, 0.35);background-color: rgb(46 184 92 / 7%);";
        case 4:
          return "border: 1px solid rgb(253 225 164);background-color: rgb(253 225 164 / 14%);";
        case 5:
          return "border: 1px solid rgb(164 183 253);background-color: rgb(164 183 253 / 15%);";
        case 6:
          return "border: 1px solid rgb(237 204 254);background-color: rgb(237 204 254 / 28%);";
        case 7:
          return "border: 1px solid rgb(254 204 217);background-color: rgb(254 204 217 / 22%);";
        case 8:
          return "border: 1px solid rgb(254 227 204);background-color: rgb(254 227 204 / 24%);";
        default:
          return "border: 1px solid rgba(46, 184, 92, 0.35);background-color: rgb(46 184 92 / 7%);";
      }
    },
    filterUserList() {
      console.log("searchType",this.searchType);
      this.userShowList = this.userList
        .filter((n) =>
          (n.fullName.includes(this.searchValue.toString()) ||
          n.phone.includes(this.searchValue.toString())) &&
          this.searchType == null
            ? true
            : n.userType == this.searchType
        )
        .map((e) => {
          return e;
        });
    },
    setUserTypeFilter(userType) {
      this.searchType = userType;
      this.filterUserList();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.userTypeColor {
  float: right;
  width: 100%;
}
.userTypeColor span {
  float: right;
  width: 6vw;
  height: 3vh;
  text-align: center;
  padding: 3px 0px;
  margin: 2px;
  cursor: pointer;
}
.userTypeColor span:hover {
  box-shadow: 0px 2px 5px 0px;
}
.userSearch {
  float: right;
  width: 100%;
  margin: 4px 5px;
  box-shadow: 0px 1px 4px 0px #000;
  border: none;
  outline: none;
}
.userListBox {
  float: right;
  width: 100%;
  height: 55vh;
  overflow-y: auto;
}
.userListBox::-webkit-scrollbar {
  width: 8px;
}
.userListBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}
.userListBox::-webkit-scrollbar-thumb {
  background: #93ff2e;
  border-radius: 10px;
}
.userListBox::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}
.deleteFilterUserType {
  background-color: red;
  color: #fff;
  width: 2vw !important;
  border-radius: 100%;
  padding: 5px !important;
}
.userItem {
  float: right;
  width: 18%;
  border-radius: var(--border-radius);
  padding: 4px;
  margin: 5px;
  cursor: pointer;
}
.userItem:hover {
  color: red;
}
.userItem h4 {
  float: right;
  width: 100%;
  text-align: center;
  font-size: 1em;
  margin: 0px;
}
.userItem h5 {
  float: right;
  width: 100%;
  text-align: center;
  font-size: 1em;
  margin: 0px;
}
.userItem hr {
  float: right;
  width: 100%;
  margin: 5px 1px;
}
</style>
