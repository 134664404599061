<template>
  <div class="master">
    <div class="userAccess">
      <input type="text" placeholder="جستجو بر اساس نام کاربر..." v-model="searchText"/>
      <CDropdown class="userAccessbutton" add-menu-classes="pt-0">
        <template #toggler>
          <i
            class="fa fa-filter"
            aria-hidden="true"
            style="margin-right: 5px"
          ></i>
        </template>
        <CDropdownItem v-for="(item, index) of userTypes" :key="index"
        @click="filterUserType(item.value)">
          <i
            class="fa fa-comments-o"
            aria-hidden="true"
            style="float: right; margin: 4px; color: #e55353"
          ></i>
          {{ item.name }}
        </CDropdownItem>
      </CDropdown>
      <button
        class="userAccessbutton"
        @click="
          () => {
            modelShow = true;
          }
        "
      >
        <i class="fa fa-plus-square" aria-hidden="true"></i>
      </button>
      <allCorrespondencesHistory :searchText="searchText" :userType="userTypeFilter"/>
    </div>
    <div class="messageBox">
      <div class="showBox">
        <div class="msg request">
          <h6 style="border-right: 3px solid #f80">کاربر کارمند مزرعه</h6>
          <h6>1401/10/10 12:54</h6>
          <p>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
            استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
            ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز،
            و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای
            زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و
            متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان
            رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد
            کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه
            راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل
            حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود
            طراحی اساسا مورد استفاده قرار گیرد.
          </p>
          <div class="btnBox">
            <button><i class="fa fa-download" aria-hidden="true"></i></button>
            <button><i class="fa fa-download" aria-hidden="true"></i></button>
          </div>
        </div>
        <div class="msg answer">
          <h6 style="border-right: 3px solid #f80">کاربر کارمند مزرعه</h6>
          <h6>1401/10/10 12:54</h6>
          <p>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
            طراحی اساسا مورد استفاده قرار گیرد.
          </p>
          <div class="btnBox">
            <button><i class="fa fa-download" aria-hidden="true"></i></button>
            <button><i class="fa fa-download" aria-hidden="true"></i></button>
          </div>
        </div>
        <div class="msg request">
          <h6 style="border-right: 3px solid #f80">کاربر کارمند مزرعه</h6>
          <h6>1401/10/10 12:54</h6>
          <p>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با لورم
            ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با طراحی
            اساسا مورد استفاده قرار گیرد.
          </p>
          <div class="btnBox">
            <button><i class="fa fa-download" aria-hidden="true"></i></button>
            <button><i class="fa fa-download" aria-hidden="true"></i></button>
          </div>
        </div>
        <div class="msg answer">
          <h6 style="border-right: 3px solid #f80">کاربر کارمند مزرعه</h6>
          <h6>1401/10/10 12:54</h6>
          <p>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
            طراحی اساسا مورد استفاده قرار گیرد. طراحی اساسا مورد استفاده قرار
            گیرد.
          </p>
          <div class="btnBox">
            <button><i class="fa fa-download" aria-hidden="true"></i></button>
            <button><i class="fa fa-download" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
      <div class="sendBox">
        <div class="actionBox">
          <button class="sendBtn">
            <i class="fa fa-paper-plane" aria-hidden="true"></i>
          </button>
          <button class="fileBtn">
            <i class="fa fa-file" aria-hidden="true"></i>
          </button>
          <button class="deleteBtn">
            <i class="fa fa-eraser" aria-hidden="true"></i>
          </button>
        </div>
        <textarea
          name="msgText"
          id="msgText"
          cols="30"
          rows="10"
          class="textBox"
          placeholder="......"
        ></textarea>
      </div>
    </div>
    <VueModal
      v-model="modelShow"
      title="لیست کاربران مرتبط با شما..."
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      :bg-in-class="`animate__fadeInDown`"
      :bg-out-class="`animate__fadeOutDown`"
      modelStyle="width:30vw;max-width:30vw !important"
    >
      <allUserConnection :userTypeAccess="userTypes" />
    </VueModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import allUserConnection from "./correspondencesSubForm/allUserConnection.vue";
import allCorrespondencesHistory from "./correspondencesSubForm/allCorrespondencesHistory.vue";
export default {
  name: "correspondeces",
  components: {
    allUserConnection,
    allCorrespondencesHistory,
  },
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
      userTypes: [],
      modelShow: false,
      searchText : "",
      userTypeFilter: null
    };
  },
  watch: {},
  methods: {
    ...mapActions("correspondences", ["GetAllUserTypeAccessToChat"]),
    async fetchData() {
      this.loading = true;
      let result = await this.GetAllUserTypeAccessToChat({});
      this.loading = false;
      if (result) {
        this.userTypes = [];
        result.forEach((element) => {
          this.userTypes.push({
            value: element.type,
            name: element.name,
          });
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
    filterUserType(userType){
      this.userTypeFilter = userType;
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.master {
  height: 100vh;
  width: 100vw;
}
.userAccess {
  float: right;
  height: 86vh;
  width: 18vw;
  margin-top: 2vh;
  margin-right: 1vw;
  box-shadow: 0px 0px 3px 1px #3c4b64;
  background-color: #fff;
  padding: 4px;
}
.userAccess input {
  float: right;
  width: 78%;
  height: 3vh;
  margin: 1%;
  outline: none;
}
.userAccessbutton {
  float: right;
  width: 8%;
  margin: 2% 2px;
  padding: 4px 0px 0px 0px;
  border: none;
  color: #fff;
  background-color: blueviolet;
  cursor: pointer;
}

.messageBox {
  float: right;
  margin-top: 2vh;
  margin-right: 1vw;
  height: 86vh;
  width: 64vw;
  box-shadow: 0px 0px 4px 2px #bbb;
}
.showBox {
  width: 63vw;
  height: 60vh;
  background-color: #fff;
  float: right;
  margin: 9px 9px;
  border-right: 2px dashed #f80;
  overflow-y: auto;
}
.showBox::-webkit-scrollbar {
  width: 8px;
}
.showBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}
.showBox::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}
.showBox::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}
.sendBox {
  width: 63vw;
  height: 23vh;
  background-color: #fff;
  float: right;
  border-right: 2px dashed rgb(0, 110, 255);
  margin: 7px 9px;
}
.actionBox {
  float: right;
  width: 6vw;
  height: 22vh;
  margin: 5px;
  margin-top: 5%;
}
.actionBox button {
  float: right;
  width: 40%;
  margin-top: 5px;
  padding: 5px 0px;
  clear: both;
  margin-right: 31%;
}
.fileBtn {
  background-color: #006eff88;
  border: 2px solid #0070ff;
}
.fileBtn:hover {
  background-color: #006eff;
  color: #fff;
}

.sendBtn {
  background-color: #07a13a88;
  border: 2px solid #07a13a;
}
.sendBtn:hover {
  background-color: #07a13a;
  color: #fff;
}
.deleteBtn {
  background-color: #a1070788;
  border: 2px solid #a10707;
}
.deleteBtn:hover {
  background-color: #a10707;
  color: #fff;
}

.actionBox button i {
  float: right;
  clear: both;
  width: 100%;
}
.textBox {
  float: right;
  width: 55vw;
  height: 22vh;
  background-color: #fff;
  padding: 5px;
  margin: 5px;
  outline: none;
}
.msg {
  float: right;
  margin: 5px 5px;
  width: fit-content;
  padding: 5px;
}
.answer {
  background-color: #a0ffc0;
}
.request {
  background-color: rgb(221, 221, 221);
}

.msg h6 {
  float: right;
  font-size: 13px;
  text-align: center;
  padding: 2px 10px;
  background-color: whitesmoke;
  direction: ltr;
}
.msg p {
  float: right;
  clear: both;
  font-size: 15px;
  padding: 3px;
}
.btnBox {
  float: right;
  clear: both;
  border-bottom: 1px solid #dadada;
  padding: 0px 4px 2px 10px;
}
.msg button {
  float: right;
  border: none;
  margin-right: 5px;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  color: #f80;
  padding-top: 5px;
}
</style>
